<template>

    <b-tab v-if="claim_audit && claim_audit.length > 0" title="Diary">
        <table>
        <tr>
            <td class="p-2">
            <b-button v-if="claim_audit_pagination.first_page_url === null">&#60;&#60; First</b-button>
            <b-button v-else @click="loadClaimAudit(claim_audit_pagination.first_page_url);">&#60;&#60; First</b-button>
            </td>
            <td class="p-2">
            <b-button v-if="claim_audit_pagination.previous_page_url === null">&#60; Prev</b-button>
            <b-button v-else @click="loadClaimAudit(claim_audit_pagination.previous_page_url);">&#60; Prev</b-button>
            </td>
            <td class="p-2">
            <b>Page
            {{ claim_audit_pagination.current_page === null ? 1 : claim_audit_pagination.current_page }}
            of 
            {{ claim_audit_pagination.last_page }}
            </b>
            </td>
            <td class="p-2">
            <b-button v-if="claim_audit_pagination.next_page_url === null">Next &#62;</b-button>
            <b-button v-else @click="loadClaimAudit(claim_audit_pagination.next_page_url);">Next &#62;</b-button>
            </td>
            <td class="p-2">
            <b-button v-if="claim_audit_pagination.last_page_url === null">Last &#62;&#62;</b-button>
            <b-button v-else @click="loadClaimAudit(claim_audit_pagination.last_page_url);">Last &#62;&#62;</b-button>
            </td>
        </tr>
        </table>
        <timeline  timeline-theme="#000000">
        <timeline-title bg-color="#000000">{{ type.ucwords() }} Diary</timeline-title>
        <timeline-item 
            v-for="item in claim_audit" 
            :key="'key1_'+item.id" 
            v-bind:bg-color="item.metadata.colour"
        >
            <b>{{ item.created }} - {{ item.metadata.description }}</b><span v-if="'name' in item.metadata">&nbsp;[{{ item.metadata.name }}]</span><br/>
            <ul>
            <li v-if="'document' in item.metadata">
                <b>Document Name: </b>{{ item.metadata.document }}<br/>
                <b-button class="mt-2" v-if="'document_url' in item.metadata" @click="openInNewTab(item.metadata.document_url);">View</b-button>
            </li>
            <li v-if="'document_description' in item.metadata">
                <b>Document Description: </b>{{ item.metadata.document_description }}<br/>
                <b-button class="mt-2" v-if="'document_url' in item.metadata" @click="openInNewTab(item.metadata.document_url);">View</b-button>
            </li>
            <li v-if="'email_template' in item.metadata">
                <b>Email Template: </b>{{ item.metadata.email_template }}<br/>
                <b-button class="mt-2" v-if="'attachment_url' in item.metadata" @click="openInNewTab(item.metadata.attachment_url);">View</b-button>
            </li>
            <li v-if="'deduction_description' in item.metadata">
                <b>Deduction Description: </b>{{ item.metadata.deduction_description }}
            </li>
            <li v-if="'amount' in item.metadata">
                <b>Amount: </b>&pound;{{ item.metadata.amount }}
            </li>
            <li v-if="'note' in item.metadata">
                <b>Note: </b>{{ item.metadata.note }}
            </li>
            <li v-if="'body' in item.metadata">
                Message Body:<br/><pre>{{ item.metadata.body }}</pre>
            </li>
            <li v-if="'answered_by' in item.metadata">
                <b>Answered By Agent: </b>{{ item.metadata.answered_by }}
            </li>
            <li v-if="'queue_wait_time' in item.metadata">
                <b>Call Queue Wait Time (seconds): </b>{{ parseFloat(item.metadata.queue_wait_time).toFixed(2) }}
            </li>
            <li v-if="'queue_wait_time' in item.metadata">
                <b>Call Length (seconds): </b>{{ parseFloat(item.metadata.call_length).toFixed(2) }}
            </li>
            <li v-if="'new_data' in item.metadata">
                <b>New Data: </b>
                <ul>
                <li v-for="(data, key) in item.metadata.new_data" :key="'key2_'+key">
                    <span v-for="(d, k) in data" :key="'key3_'+k">
                    <b style="text-transform: capitalize;">{{ String(k).replace(/_/g,' ') }}:</b> {{ d }}
                    </span>
                </li>
                </ul>
            </li>
            </ul>
            <ul v-if="'audit' in item.metadata">
            <li v-for="audit in item.metadata.audit" :key="audit.index">
                <b>{{ audit.step_no }}</b> - {{ audit.name }}<br/>
                <b>Safe to proceed: {{ audit.result ? 'YES' : 'NO' }}</b><br/>
                <ul>
                <li v-for="(a, k) in audit.data" :key="k">
                <b style="text-transform: capitalize;">{{ String(k).replace(/_/g,' ') }}:</b> {{ a }}<br/>
                </li>
                </ul>
            </li>
            </ul>
            <ul v-if="'check' in item.metadata">
            <li><b>Check Name: </b>{{ item.metadata.check.description }}</li>
            <li><b>Check Type: </b>{{ item.metadata.check.type }}</li>
            <li><b>Status: </b>{{ item.metadata.check.status }}</li>
            </ul>
        </timeline-item>
        </timeline>
        <table>
        <tr>
            <td class="p-2">
            <b-button v-if="claim_audit_pagination.first_page_url === null">&#60;&#60; First</b-button>
            <b-button v-else @click="loadClaimAudit(claim_audit_pagination.first_page_url);">&#60;&#60; First</b-button>
            </td>
            <td class="p-2">
            <b-button v-if="claim_audit_pagination.previous_page_url === null">&#60; Prev</b-button>
            <b-button v-else @click="loadClaimAudit(claim_audit_pagination.previous_page_url);">&#60; Prev</b-button>
            </td>
            <td class="p-2">
            <b>Page
            {{ claim_audit_pagination.current_page === null ? 1 : claim_audit_pagination.current_page }}
            of 
            {{ claim_audit_pagination.last_page }}
            </b>
            </td>
            <td class="p-2">
            <b-button v-if="claim_audit_pagination.next_page_url === null">Next &#62;</b-button>
            <b-button v-else @click="loadClaimAudit(claim_audit_pagination.next_page_url);">Next &#62;</b-button>
            </td>
            <td class="p-2">
            <b-button v-if="claim_audit_pagination.last_page_url === null">Last &#62;&#62;</b-button>
            <b-button v-else @click="loadClaimAudit(claim_audit_pagination.last_page_url);">Last &#62;&#62;</b-button>
            </td>
        </tr>
        </table>
    </b-tab>

</template>

<script>
import axios from '../../../../shared/axios/auth-no-loader'
import { Timeline, TimelineItem, TimelineTitle } from 'vue-cute-timeline'

export default {
    components: {
      Timeline,
      TimelineItem,
      TimelineTitle,
    },
    name: 'DiaryTab',
    props: {
      type: {
        required: true,
      },
    },
    data () {
        return {
            loaded: false,
            response: {},
            claim_audit: null,
            claim_audit_pagination: null,
        }
    },
    methods: {
        loadClaimAudit(url=null) {

            if(url === null){
                if(this.type == "claim"){
                    url = '/claim-audit/' + this.$route.params.id + '/claim/';
                } else if(this.type == "customer" || this.type == "vet"){
                    url = '/users/customer/audit/' + this.$route.params.id + '/customer/';
                }
            }
            axios.get(url).then(
                response => {
                    this.claim_audit = response.data.data.filter((audit) => {
                        return (Object.keys(audit.metadata).length > 0);
                    })
                    this.claim_audit_pagination = response.data.pagination
                    this.scrollToTop()
                }
            )

        },
        openInNewTab(url){
            window.open(url, "_blank");
        },
        scrollToTop(){
            window.scrollTo(0,0);
        }
    },
    mounted() {
      this.loadClaimAudit()
    }

}

</script>
